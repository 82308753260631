<template>
  <div class="template-mail-cdg">
    <!-- TEMPLATE MAILS FRAIS -->
    <div class="row box-mail-frais mb-1">
      <div class="col-2 ">
        <div class="sub-box" >
          <div class="title-mail-cdg" v-if="dataToUse && dataToUse.lot != null">Sociéte</div>
          <v-checkbox
          v-if="dataToUse && dataToUse.lot != null"
            class="label-checked-global"
            v-model="checkAllFiliale"
            @change="checkAllFilialeMethod"
            color="#704ad1"
            :value="true"
            :unchecked-value="false"
            :true-value="true"
            :false-value="false"
            label="Tous"
            hide-details
          ></v-checkbox>
          <div class="content-checked" v-if="dataToUse && dataToUse.lot != null">
            <div  
              class="d-flex content-checked-items "
              v-for="(client, indexClient) in dataToUse.clients"
              :key="'client' + indexClient"
            >
              <v-checkbox
                @change="verifCheckAll()"
                v-model="client.check"
                color="#704ad1"
                :label="client.name_client"
                :true-value="true"
                :false-value="false"
                hide-details
                :class="{
                  'check-title-mail-cdg-active': indexClient == indexM
                }"
              >
                <template v-slot:label>
                  <div @click.prevent.stop="indexM = indexClient">
                    {{ client.name_client }}
                  </div>
                </template>
              </v-checkbox>
              <div>
                <font-awesome-icon
                  v-if="indexClient == indexM"
                  class="icon-selected-filiale mr-2"
                  icon="arrow-right"
                />
              </div>
            </div>
          </div>
          <div class="action-client">
            <v-btn
              class="color-btn-email"
              small
              text
              @click.prevent.stop="sendMail"
              :disabled="computedDisabledSend"
              v-if="dataToUse && dataToUse.lot != null"
            >
              <span>
                Envoyer
                <div class="loading ml-2"></div>
              </span>
            </v-btn>
          </div>
        </div>
      </div>
      <div class="col-10">
        <div class="sub-box">
          <div class="action-client mb-2">
            <!-- <b-button
              class="button-valide-style mr-4"
              @click.prevent.stop="confirmFilialeMail()"
            >
              <span>
                Confirmer
                <div class="loading ml-2"></div>
              </span>
            </b-button> -->
            <span class="lot-title" v-if="dataToUse && dataToUse.lot != null"> {{ dataToUse.lot }}</span>
            <span class="lot-title-error"  v-if="dataToUse && dataToUse.message != null"> {{ dataToUse.message }}</span>
          </div>
          <div class="editor-vendeur-content" v-if="dataToUse && dataToUse.clients && dataToUse.clients.length > 0">
            <step-content :dataToUse="dataToUse.clients[indexM]" />
          </div>
        </div>
      </div>
    </div>
    <div class="actionModelMailCdg d-flex justify-content-center">
      <v-btn
        color="#704ad1"
        small
        outlined
        class="btn-circle mr-2"
        v-if="indexM < dataToUse.clients.length && indexM > 0"
        @click="previousMail()"
      >
        <span>
          Précédent
          <div class="loading ml-2"></div>
        </span>
      </v-btn>
      <span
        v-for="(i, indexlot) in dataToUse.clients"
        :key="'lotIndex' + indexlot"
        class="circleStep"
        :class="{ circleStepActive: indexlot == indexM }"
      ></span>
      <v-btn
        small
        outlined
        color="#704ad1"
        class="btn-circle ml-2"
        v-if="indexM < dataToUse.clients.length - 1"
        @click="nextMail()"
      >
        <span>
          Suivant
          <div class="loading ml-2"></div>
        </span>
      </v-btn>
    </div>
    <custom-swal
      :dataToShow="resultMail"
      v-if="showResultSwal"
      @closeResultSwal="showResultSwal = false"
    />
    <!-- END TEMPLATE MAILS FRAIS -->
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
export default {
  props: {
    dataToUse: { required: true },
    changeVendeurIndex: { required: true }
  },
  data() {
    return {
      indexM: 0,
      checkAllFiliale: true,
      resultMail: null,
      showResultSwal: false,
      loaderSendMail: false
    };
  },
  computed: {
    ...mapGetters(['getLoaderSendMailCdg']),
    computedDisabledSend() {
      return (
        this.loaderSendMail ||
        (this.dataToUse != null &&
          this.dataToUse.clients.filter(i => i.check == true).length == 0)
      );
    }
  },
  methods: {
    ...mapActions(['sendMailCdg']),
    previousMail() {
      this.indexM = this.indexM - 1;
    },
    nextMail() {
      this.indexM = this.indexM + 1;
    },
    confirmFilialeMail() {
      this.dataToUse.clients[this.indexM].check = true;
      this.verifCheckAll();
      if (this.indexM < this.dataToUse.clients.length - 1) {
        this.indexM = this.indexM + 1;
      }
    },
    async sendMail() {
      this.loaderSendMail = true;
      if (
        this.dataToUse &&
        this.dataToUse.clients &&
        this.dataToUse.clients.filter(item => item.check == true).length > 0
      ) {
        let payload = new FormData();
        this.dataToUse.clients.forEach((element, i) => {
          if (element.check == true) {
            payload.append(
              'mail_to_send[' + i + '][send_to_vendeur]',
              element.send_to_vendeur
            );
            payload.append('mail_to_send[' + i + '][lot]', this.dataToUse.lot);
            payload.append(
              'mail_to_send[' + i + '][lot_id]',
              this.dataToUse.lot_id
            );
            payload.append('mail_to_send[' + i + '][html]', element.html);
            //client property
            payload.append(
              'mail_to_send[' + i + '][client][id]',
              element.client.id
            );
            payload.append(
              'mail_to_send[' + i + '][client][name]',
              element.client.name
            );
            payload.append(
              'mail_to_send[' + i + '][client][email]',
              element.client.email
            );
            element.client.extra_mails.forEach((extra, iExtra) => {
              payload.append(
                'mail_to_send[' + i + '][client][extra_mails][' + iExtra + ']',
                extra
              );
            });
            //end proprty client
            //vendeur property
            payload.append(
              'mail_to_send[' + i + '][vendeur][id]',
              element.vendeur.id
            );
            payload.append(
              'mail_to_send[' + i + '][vendeur][name]',
              element.vendeur.name
            );
            payload.append(
              'mail_to_send[' + i + '][vendeur][email]',
              element.vendeur.email
            );
            element.vendeur.extra_mails.forEach((extra, iExtra) => {
              payload.append(
                'mail_to_send[' + i + '][vendeur][extra_mails][' + iExtra + ']',
                extra
              );
            });
            //end proprty vendeur
            //facture ids
            element.ids_facture.forEach((id, iId) => {
              payload.append(
                'mail_to_send[' + i + '][ids_facture][' + iId + ']',
                id
              );
            });
          }
        });

        const response = await this.sendMailCdg(payload);
        if (response.succes) {
          this.$emit('eventMailSend');
          // this.dataToUse.mailSend = true;
          this.resultMail = response.data;
          this.showResultSwal = true;
          this.loaderSendMail = false;
        } else {
          this.loaderSendMail = false;
        }
      }
    },
    checkAllFilialeMethod() {
      this.dataToUse.clients.map(i => (i.check = this.checkAllFiliale));
    },
    verifCheckAll() {
      if (
        this.dataToUse.clients.filter(i => i.check == true).length ==
        this.dataToUse.clients.length
      ) {
        this.checkAllFiliale = true;
      } else {
        this.checkAllFiliale = false;
      }
    }
  },
  components: {
    stepContent: () => import('./stepContent.vue'),
    customSwal: () => import('./customSwal.vue')
  },
  watch: {
    changeVendeurIndex: function() {
      this.indexM = 0;
      this.checkAllFiliale = true;
    }
  }
};
</script>

<style scoped lang="scss">
.template-mail-cdg {
  height: calc(100vh - 120px);
  .box-mail-frais {
    height: calc(100vh - 105px);
    .sub-box {
      height: calc(100vh - 122px);
      overflow-x: hidden;
      background-color: #f6f5fb;
      border: 1px solid #e0ddf0;
      margin-bottom: 8px;
      border-radius: 10px;
      padding: 6px;
      .content-client {
        height: calc(100vh - 210px);
      }
      .action-client {
        text-align-last: center;
        height: 26px;
        .lot-title {
          position: absolute;
          right: 37px;
          font-weight: 700;
          margin-top: 8px;
          font-size: 20px;
          color: #704ad1;
        }
        .lot-title-error {
          font-weight: 700;
          margin-top: 20px;
          font-size: 20px;
          padding-top: 20px;
          color: red;
        }
      }
      .check-title-mail-cdg {
        font-size: 10px;
        font-weight: 600;
        cursor: pointer;
      }
      .check-title-mail-cdg-active {
        font-size: 13px;
        font-weight: 800;
      }
      .check-icon-mail-cdg {
        height: 20px;
        width: 20px;
        border-radius: 4px;
        margin-right: 10px;
        background-color: #fff;
      }
      .checked-icon-mail-cdg {
        background: #7b58d5;
        color: #fff;
        text-align: center;
        border-color: #704ad1;
        font-size: 9px;
      }
      .icon-selected-filiale {
        color: #7b58d5;
      }
    }
    .sub-box::-webkit-scrollbar {
      width: 5px;
      height: 5px;
    }
    .sub-box::-webkit-scrollbar-track {
      box-shadow: inset 0 0 5px #f0eef8;
      border-radius: 10px;
    }
    .sub-box::-webkit-scrollbar-thumb {
      background: #704ad1;
      border-radius: 7px;
    }
  }
  // .editor-vendeur-content {
  // height: calc(100vh - 120px);
  // }
  .actionModelMailCdg {
    // padding-bottom: 18px;
    align-items: center;
    justify-content: center;
    // padding-top: 18px;
    .btn-circle {
      height: 28px;
      min-width: 64px;
      padding: 5px 8px;
      margin-bottom: -6px;
    }
  }
  .content-checked-items {
    align-items: flex-end;
    justify-content: space-between;
  }
  .title-mail-cdg {
    color: #7b58d5;
    text-align: center;
    font-weight: 600;
    font-size: 15px;
    margin-bottom: -9px;
  }
  .color-btn-email {
    background: rgb(112, 74, 209);
    color: #ffffff !important;
    caret-color: rgb(112, 74, 209);
  }
  .content-checked {
    height: calc(100vh - 241px);
    overflow: auto;
    margin-top: 4px;
    margin-bottom: 15px;
    .v-input .v-label {
      font-size: 10px !important;
      font-family: 'Montserrat', sans-serif;
    }
  }
  .content-checked::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  .content-checked::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #f0eef8;
    border-radius: 10px;
  }
  .content-checked::-webkit-scrollbar-thumb {
    background: #704ad1;
    border-radius: 7px;
  }
}
</style>
<style lang="scss">
.content-checked {
  .v-input .v-label {
    font-size: 10px !important;
    font-family: 'Montserrat', sans-serif;
  }
  .v-input--selection-controls {
    margin-top: 0px;
    padding: 0px;
  }
}
.label-checked-global {
  .v-label {
    font-size: 10px !important;
    font-family: 'Montserrat', sans-serif;
  }
}
</style>
